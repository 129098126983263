body {
  margin: 0;
  font-family: 'Rubik', 'Noto Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@keyframes home__core-values-card--fade-in {
  from {
    opacity: 0;
    margin-bottom: 0px;
    margin-top: 32px;
  }

  to {
    opacity: 1;
    margin-bottom: 32px;
    margin-top: 0px;
  }
}
