@keyframes fade-out-overlay {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in-logo {
  0% {
    width: 5%;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out-logo {
  0% {
    opacity: 1;
  }
  100% {
    width: 7.5%;
    opacity: 0;
  }
}
